/* global window */

import Button from '@material-ui/core/Button'
import React from 'react'

import stripe from 'services/stripe-client'

export default function CheckoutButton({
  cartProductList,
  formValues,
  setHelperTextVAT,
  setShowErrorVAT,
  setHelperTextPhone,
  setShowErrorPhone,
  setHelperTextResellerID,
  setShowErrorResellerID,
  setChildrenCheck,
}) {
  const fiscalCode = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/
  const VATCode = /^[0-9]{11}$/
  const numbers = /^[0-9+]*$/
  const alphanumeric = /^[a-zA-Z0-9]*$/

  const onClick = async () => {
    setHelperTextVAT('')
    setHelperTextPhone('')
    setHelperTextResellerID('')
    setChildrenCheck('')
    setShowErrorVAT(false)
    setShowErrorPhone(false)
    setShowErrorResellerID(false)

    if (formValues.textFieldVAT === '') {
      setHelperTextVAT('il campo non può essere vuoto')
      setShowErrorVAT(true)
    } else if (formValues.textFieldVAT.length === 16 && !formValues.textFieldVAT.match(fiscalCode)) {
      setHelperTextVAT('codice fiscale errato')
      setShowErrorVAT(true)
    } else if (formValues.textFieldVAT.length === 11 && !formValues.textFieldVAT.match(VATCode)) {
      setHelperTextVAT('part. IVA errata')
      setShowErrorVAT(true)
    } else if (formValues.textFieldVAT.length !== 11 && formValues.textFieldVAT.length !== 16) {
      setHelperTextVAT('codice fiscale o part. IVA errati')
      setShowErrorVAT(true)
    } else if (formValues.textFieldPhone === '') {
      setHelperTextPhone('il campo non può essere vuoto')
      setShowErrorPhone(true)
    } else if (!formValues.textFieldPhone.match(numbers)) {
      setHelperTextPhone('il campo deve contenere solo numeri')
      setShowErrorPhone(true)
    } else if (formValues.textFieldResellerID.length > 5) {
      setHelperTextResellerID('massimo 5 caratteri')
      setShowErrorResellerID(true)
    } else if (!formValues.textFieldResellerID.match(alphanumeric)) {
      setHelperTextResellerID('il campo deve contenere solo lettere o numeri')
      setShowErrorResellerID(true)
    } else if (formValues.checkBoxEssentials === false) {
      setChildrenCheck('questo consenso è necessario')
    } else {
      const Stripe = await stripe()

      const productsCart = cartProductList.map(({ price, quantity }) => ({ price, quantity }))

      Stripe.redirectToCheckout({
        lineItems: productsCart,
        mode: 'payment',
        clientReferenceId: `${formValues.textFieldVAT}-${formValues.textFieldPhone}-${formValues.textFieldResellerID}-${formValues.checkBoxMarketing}`,
        successUrl: `${window.location.origin}/order-success`,
        cancelUrl: `${window.location.origin}/cart`,
        billingAddressCollection: 'required',
      })
    }
  }

  return (
    <>
      <Button className="styled-button cart" color="secondary" fullWidth onClick={onClick}>
        Procedi con l&apos;acquisto
      </Button>
    </>
  )
}
