import React from 'react'

import { FormControl, NativeSelect } from './Select.styles'

export default function Select({ name, valueSelected, setValueSelected, options }) {
  const handleChange = (event) => {
    setValueSelected(event.target.value)
  }

  return (
    <FormControl>
      <NativeSelect
        disableUnderline
        value={valueSelected}
        onChange={handleChange}
        inputProps={{
          name,
          id: name,
        }}
      >
        {options.map(({ key, value, name: optionName }) => (
          <option key={key} value={value}>
            {optionName}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )
}
