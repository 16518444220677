import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

import SEO from 'components/elements/SEO/SEO'
import { useCartContext } from 'context/CartContext'
import { useThemeContext } from 'context/ThemeContext'

import CartTitle from './CartTitle'
import CartList from './CartList'
import CartSummary from './CartSummary'
import CartEmpty from './CartEmpty'
import { Paper } from './CartTemplate.styles'

export default function CartTemplate() {
  const { isMobile } = useThemeContext()
  const [{ cartProductList, cartProductQuantity }, dispatch] = useCartContext()

  if (cartProductQuantity === 0) {
    return <CartEmpty />
  }

  return (
    <>
      <SEO title="Il tuo carrello" />
      <section id="cart-template" className="closer">
        <Grid container spacing={3} direction={isMobile ? 'column' : 'row'}>
          {isMobile ? (
            <>
              <Grid item xs={12}>
                <CartTitle cartProductQuantity={cartProductQuantity} />
                <CartList cartProductList={cartProductList} dispatch={dispatch} isMobile={isMobile} />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <CartSummary cartProductList={cartProductList} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={8}>
                <Paper elevation={2}>
                  <CartTitle cartProductQuantity={cartProductQuantity} />
                  <CartList cartProductList={cartProductList} dispatch={dispatch} isMobile={isMobile} />
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={2}>
                  <CartSummary cartProductList={cartProductList} />
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </section>
    </>
  )
}
