import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function CartTitle({ cartProductQuantity }) {
  return (
    <Typography>
      {cartProductQuantity}
      &nbsp;Prodotti
    </Typography>
  )
}
