import React from 'react'
import Link from 'components/foundations/Link/Link'

export default function CartEmpty() {
  return (
    <div className="closer" style={{ textAlign: 'center', padding: '2rem' }}>
      <p>
        <strong>IL TUO CARRELLO È VUOTO.</strong>
      </p>
      <Link to="/corsi">
        <span className="btn product-btn">Ritorna ai corsi</span>
      </Link>
    </div>
  )
}
