import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from 'components/foundations/Link/Link'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import CheckboxIcon from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import { withStyles, makeStyles } from '@material-ui/core/styles'

import priceFormat from 'utils/priceFormat'
import CheckoutButton from './CheckoutButton'

import { Row } from './CartSummary.styles'

const GreenCheckbox = withStyles({
  root: {
    color: 'default',
    '&$checked': {
      color: `#245daa`,
    },
  },
  checked: {},
})((props) => <CheckboxIcon size="small" color="default" {...props} />)

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputLabel-root': {
      color: `#245daa`,
      fontSize: `0.8rem`,
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: `#245daa`,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: `0.7rem`,
    },
  },
}))

export default function CartSummary({ cartProductList }) {
  const [formValues, setFormValues] = React.useState({
    textFieldVAT: '',
    textFieldPhone: '',
    textFieldResellerID: '',
    checkBoxEssentials: false,
    checkBoxMarketing: false,
  })
  const [showErrorVAT, setShowErrorVAT] = React.useState(false)
  const [showErrorPhone, setShowErrorPhone] = React.useState(false)
  const [showErrorResellerID, setShowErrorResellerID] = React.useState(false)
  const [helperTextVAT, setHelperTextVAT] = React.useState('')
  const [helperTextPhone, setHelperTextPhone] = React.useState('')
  const [helperTextResellerID, setHelperTextResellerID] = React.useState('')
  const [childrenCheck, setChildrenCheck] = React.useState('')

  const changeHandler = (e) => {
    const { target } = e
    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    setFormValues((f) => ({ ...f, [name]: value }))
  }

  const classes = useStyles()

  const { subtotal, total } = getSummaryPrices({ cartProductList })

  const { checkBoxEssentials, checkBoxMarketing } = formValues
  const errorCheck = [checkBoxEssentials, checkBoxMarketing].filter((v) => v).length !== 1

  return (
    <>
      <Row>
        <Typography>Subtotale</Typography>
        {<Typography>{subtotal}</Typography>}
      </Row>
      <Row>
        <Typography>Totale (IVA inclusa)</Typography>
        {<Typography>{total}</Typography>}
      </Row>
      <form className="cart-form">
        <FormControl required error={errorCheck} component="fieldset">
          <TextField
            error={showErrorVAT}
            className={classes.root}
            required
            id="textFieldVAT"
            name="textFieldVAT"
            label="Cod. Fiscale / Part. IVA"
            helperText={helperTextVAT}
            onChange={changeHandler}
          />
          <TextField
            error={showErrorPhone}
            className={classes.root}
            required
            id="textFieldPhone"
            name="textFieldPhone"
            label="Numero di telefono"
            helperText={helperTextPhone}
            onChange={changeHandler}
          />
          <TextField
            error={showErrorResellerID}
            className={classes.root}
            id="textFieldResellerID"
            name="textFieldResellerID"
            label="Codice rivenditore"
            helperText={helperTextResellerID}
            onChange={changeHandler}
          />

          <div className="gdpr-checkbox">
            <FormControlLabel
              className={classes.root}
              control={
                <GreenCheckbox
                  checked={formValues.checkBoxEssentials}
                  onChange={changeHandler}
                  name="checkBoxEssentials"
                  id="checkBoxEssentials"
                />
              }
              label="Autorizzo al trattamento dei dati per l'esecuzione del servizio e di tutte le attività ad esso connesse*"
            />
            <FormHelperText error={errorCheck}>{childrenCheck}</FormHelperText>
            <FormControlLabel
              className={classes.root}
              control={
                <GreenCheckbox
                  checked={formValues.checkBoxMarketing}
                  onChange={changeHandler}
                  name="checkBoxMarketing"
                  id="checkBoxMarketing"
                />
              }
              label="Autorizzo allo svolgimento di attività di marketing e promozionali di prodotti e servizi"
            />
          </div>
          <div>
            <Typography className="cookie-link" variant="caption">
              <Link to={`/privacy-cookie-policy`}>
                Clicchi qui per avere maggiori informazioni sull&apos;uso dei dati.
              </Link>
            </Typography>
          </div>

          <CheckoutButton
            cartProductList={cartProductList}
            formValues={formValues}
            setHelperTextVAT={setHelperTextVAT}
            setShowErrorVAT={setShowErrorVAT}
            setHelperTextPhone={setHelperTextPhone}
            setShowErrorPhone={setShowErrorPhone}
            setHelperTextResellerID={setHelperTextResellerID}
            setShowErrorResellerID={setShowErrorResellerID}
            setChildrenCheck={setChildrenCheck}
          />
        </FormControl>
      </form>
    </>
  )
}

function getSummaryPrices({ cartProductList }) {
  const subtotal = cartProductList.reduce((acc, item) => acc + item.quantity * item.productPrice, 0)
  const taxVAT = (subtotal * 22) / 100
  const total = subtotal + taxVAT

  return {
    subtotal: priceFormat(subtotal),
    total: priceFormat(total),
  }
}
