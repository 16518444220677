/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react'
import List from '@material-ui/core/List'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Button from 'components/foundations/Button/Button'
import Select from 'components/foundations/Select/Select'
import CustomImage from 'components/foundations/Image/CustomImage'
import priceFormat from 'utils/priceFormat'

import { ListItem, ListItemAvatar, ListItemText } from './CartList.styles'

export default function CartList({ cartProductList, dispatch, isMobile }) {
  const options = Array.from({ length: 20 }, (v, i) => ({ key: i + 1, value: i + 1, name: i + 1 }))

  return (
    <List>
      {cartProductList.map((item, i) => {
        const productId = [item.productId].toString()
        const productName = [item.productName].toString()
        const productPrice = priceFormat([item.productPrice])

        if (!productId) {
          return null
        }

        const onClick = () => {
          dispatch({ type: 'REMOVE_FROM_CART', payload: { productId } })
        }

        const formSelectQuantity = (
          <Select
            name="quantity"
            valueSelected={item.quantity}
            setValueSelected={(value) =>
              dispatch({ type: 'SET_PRODUCT_QUANTITY', payload: { productId, quantity: value } })
            }
            options={options}
          />
        )

        return (
          <Fragment key={i}>
            <ListItem className="cart-item" key={productId}>
              <ListItemAvatar>
                <CustomImage filename={item.productImage[0]} alt={item.productName} />
              </ListItemAvatar>

              <div className="item-infos">
                <ListItemText>
                  <Typography className="cart-product-name" variant="subtitle1" color="textPrimary">
                    {productName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Prezzo IVA esclusa (cad.): {productPrice}
                  </Typography>
                </ListItemText>

                {isMobile && formSelectQuantity}

                <Button variant="buttonless" onClick={onClick}>
                  <Typography variant="caption">Rimuovi</Typography>
                </Button>
              </div>

              {!isMobile && <ListItemSecondaryAction>{formSelectQuantity}</ListItemSecondaryAction>}
            </ListItem>
            <Divider />
          </Fragment>
        )
      })}
    </List>
  )
}
